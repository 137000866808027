import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`D`}<em parentName="p">{`eload Week`}</em></p>
    <p>{`DB Bench Press 3×12`}</p>
    <p>{`DB Pullovers 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time:`}</p>
    <p>{`10-Ring Dips`}</p>
    <p>{`20-Renegade Rows (35/25’s, alternating, 10/arm)`}</p>
    <p>{`50-Double Unders`}</p>
    <p><em parentName="p">{`*`}{`compare time to 9/11/20`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      